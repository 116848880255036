'use client';
import { useState } from 'react';
import { Flex, Row, Col } from 'antd';
import { PageDiningSingleBlockFragment, VenueItemFragment } from '@/@types/generated/sitecore';
import ImagePreview, { ImagePreviewGroup } from '../ImagePreview';
import { Slider, SimpleImageSlider, SliderControlButton } from '../Slider';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import Button, { GeneralButtonType } from '../Button';
import Heading from '../Heading';
import DiningDialog from './Dialog';
import WaveRightIcon from '../SVG/WaveRightIcon';
import WaveLeftIcon from '../SVG/WaveLeftIcon';
import { WATrackerClickEvn, getButtonLinkWithCID } from '@/utils/wa';
import RichText from '../RichText';
import { withFullPath } from '@/utils/utils';

export type AlbumListType = {
    images: {
        id: number;
        src?: string;
        mediaType?: 'Photo' | 'Video';
        mediaCaption?: string;
        videoSrc?: string;
    }[];
};

export const VenuesSlider = ({ data }: { data?: AlbumListType }) =>
    data && (
        <ImagePreviewGroup imageList={data.images} total={(data.images || []).length}>
            <Slider
                settings={{
                    // loop: true,
                    slidesPerView: 1,
                }}
                className={'w-full'}
            >
                {SimpleImageSlider({
                    data: data.images,
                    className: 'rounded-[5px]',
                    rootClassName: 'w-full xmd:max-w-[868px]',
                })}
                <SliderControlButton className={'mt-8 mb-12'} />
            </Slider>
        </ImagePreviewGroup>
    );

const PageDiningSingleBlock = (props: { content: PageDiningSingleBlockFragment }) => {
    const { content } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const globalStore = useGlobalStore();
    const musicWaveColor = globalStore.currentTheme?.musicWaveColor;

    const {
        subHeaderTitle,
        diningDescription,
        venueLink,
        blockLayout,
        buttonType,
        buttonText,
        buttonLink,
        titleIconOptions,
    } = content;
    const colLayout = {
        LeftTextRightMedia: [
            {
                span: 12,
                order: 1,
            },
            {
                span: 12,
                order: 2,
            },
        ],
        LeftMediaRightText: [
            {
                span: 12,
                order: 2,
            },
            {
                span: 12,
                order: 1,
            },
        ],
    };

    const layout: keyof typeof colLayout =
        (blockLayout?.value as keyof typeof colLayout) || 'LeftTextRightMedia';

    const venueAlbumList = venueLink?.targetItem?.venueAlbums.map((album) => {
        if (album.template.name !== 'VenueAlbumFolder') return [{ images: [] }];
        return {
            images: album.albumList.map((imageItem, index) => {
                return {
                    id: index,
                    src: imageItem.contentImage?.src,
                    mediaType: imageItem.mediaType?.value,
                    videoSrc: imageItem.videoSrc?.value,
                    mediaCaption: imageItem.mediaCaption?.value,
                };
            }),
        };
    }) || [{ images: [] }];

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const album = (
        venueAlbumList.every(Boolean) ? venueAlbumList[0] : [{ images: [] }]
    ) as AlbumListType;

    const TITLE_ICON = {
        titleIconRight: () => <WaveRightIcon iconColor={musicWaveColor}></WaveRightIcon>,
        titleIconLeft: () => <WaveLeftIcon iconColor={musicWaveColor}></WaveLeftIcon>,
    };

    const TitleIcon = titleIconOptions?.value
        ? TITLE_ICON[titleIconOptions?.value as keyof typeof TITLE_ICON]
        : () => <></>;

    return (
        <div>
            <Row gutter={[60, 30]}>
                <Col span={24} lg={colLayout[layout][0]} className='mt-6'>
                    <Row gutter={[0, 24]}>
                        <Col span={24}>
                            <Flex vertical gap={8}>
                                <RichText html={subHeaderTitle?.rendered || ''} />
                                <TitleIcon />
                            </Flex>
                        </Col>
                        <Col span={24}>
                            <Heading
                                className={'text-base-font-color'}
                                level={4}
                                text={venueLink?.targetItem?.venueName?.value}
                            />
                        </Col>
                        <Col span={24}>
                            <ImagePreview
                                setting={{
                                    src: withFullPath(
                                        venueLink?.targetItem?.venueLogoImage?.src || '',
                                    ),
                                    alt: '',
                                    width: '300px',
                                }}
                            />
                        </Col>
                        <Col span={24}>
                            <RichText html={venueLink?.targetItem?.venueLocation?.rendered || ''} />
                        </Col>
                        <Col span={24}>
                            <RichText html={diningDescription?.rendered || ''} />
                        </Col>
                        <Col span={24} className='mt-2'>
                            <Button
                                data={{
                                    setting: {
                                        onClick: () => {
                                            // WATrackerClickEvn(
                                            //     `RnE/HW_ClickVenue-${venueLink?.targetItem?.venueName?.value}_Venue-Card`,
                                            // );
                                            if (buttonType?.value === 'Popup') {
                                                setIsModalOpen(true);
                                            }
                                        },
                                    },
                                    buttonLink: getButtonLinkWithCID(
                                        buttonLink?.value,
                                        `${globalStore.pageID}_Section-Banner`,
                                    ),
                                }}
                            >
                                <GeneralButtonType
                                    label={buttonText?.value}
                                    icon={'arrowRightBK'}
                                    // iconColor={themeColorCode}
                                />
                            </Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} lg={colLayout[layout][1]}>
                    <VenuesSlider data={album} />
                </Col>
            </Row>
            <DiningDialog
                data={{
                    setting: {
                        open: isModalOpen,
                        onCancel: handleCancel,
                        width: 800,
                    },
                    className: 'border-[#EAE219] shadow-[0_0_10px_0_#EAE219]',
                }}
                content={content?.venueLink?.targetItem as VenueItemFragment}
                album={album}
            />
        </div>
    );
};

export default PageDiningSingleBlock;
