'use client';
import { Flex, Row, Col, Image } from 'antd';
import Dialog, { dialogProps } from '../Dialog';
import Heading from '../Heading';
import Text from '../Text';
import { AlbumListType, VenuesSlider } from './index';
import { VenueItemFragment } from '@/@types/generated/sitecore';
import { isRichText } from '@/utils/utils';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import WaveRightIcon from '../SVG/WaveRightIcon';
import WaveLeftIcon from '../SVG/WaveLeftIcon';
import RichText from '../RichText';
import { useTranslation } from '@/i18n/use-transition.client';

type DiningDialogType = {
    data: dialogProps['data'];
    content: VenueItemFragment;
    album?: AlbumListType;
    currentMusicWaveColor?: string;
};

const ModalTemplate = ({
    content,
    album,
    currentMusicWaveColor,
}: Omit<DiningDialogType, 'data'>) => {
    const data = content;
    const { t } = useTranslation();
    const detailsItemsKey = {
        venueLocationDetail: t('venueLocation'),
        venueAccess: t('venueAccess'),
        openingHours: t('venueOpeningHours'),
        enquiryHotline: t('venueEnquiryHotline'),
        food: t('venueFood'),
        price: t('venuePrice'),
        dressCode: t('venueDressCode'),
        facilities: t('venueFacilities'),
        remarks: t('venueRemarks'),
    };

    const moreDetailsItems =
        (data &&
            Object.keys(data).reduce(
                (pre: Array<never | { label: string; content: string }>, current) => {
                    if (detailsItemsKey[current as keyof typeof detailsItemsKey]) {
                        const currentItem = (data[current as keyof typeof data] || {}) as {
                            value?: string;
                            rendered?: string;
                        };
                        return [
                            ...pre,
                            {
                                label: detailsItemsKey[current as keyof typeof detailsItemsKey],
                                content: currentItem?.value || currentItem?.rendered || '',
                            },
                        ];
                    }
                    return pre;
                },
                [],
            )) ||
        [];

    const globalStore = useGlobalStore();
    const musicWaveColor = globalStore.currentTheme?.musicWaveColor;

    const TITLE_ICON = {
        titleIconRight: () => (
            <WaveRightIcon iconColor={currentMusicWaveColor || musicWaveColor}></WaveRightIcon>
        ),
        titleIconLeft: () => (
            <WaveLeftIcon iconColor={currentMusicWaveColor || musicWaveColor}></WaveLeftIcon>
        ),
    };

    const TitleIcon = content.titleIconOptions?.value
        ? TITLE_ICON[content.titleIconOptions?.value as keyof typeof TITLE_ICON]
        : () => null;

    return (
        <Flex vertical gap={48}>
            <Flex vertical gap={8} align={'center'} className='mt-[56px]'>
                <Flex vertical gap={8} align={'center'}>
                    <Heading
                        className={'text-base-font-color'}
                        level={3}
                        text={data?.venueName?.value || ''}
                        style={{ color: '#FFFFFF' }}
                    />
                    <TitleIcon />
                </Flex>
                <Row align={'middle'}>
                    {/* <Col xs={24} lg={6}>
                        <Image preview={false} src={data?.venueLogoImage?.src} alt='' />
                    </Col> */}
                    <Col span={24}>
                        <RichText html={data?.venueDescription?.rendered || ''} />
                    </Col>
                </Row>
                <Row className={'w-full'}>
                    <Col span={24}>
                        <VenuesSlider data={album} />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Flex vertical gap={16}>
                            <></>
                            {moreDetailsItems.map((item: any, index: number) => (
                                <Flex key={index} vertical gap={16}>
                                    <hr className={'border-content-divide-color'} />
                                    <Row>
                                        <Col span={8}>
                                            <Text
                                                level={'body3'}
                                                text={item.label}
                                                className='text-base-font-color xmd:ml-7'
                                            />
                                        </Col>
                                        <Col span={16}>
                                            {isRichText(item.content) ? (
                                                <RichText html={item.content || ''} />
                                            ) : (
                                                <>
                                                    <Text
                                                        level={'body3'}
                                                        text={item.content}
                                                        className='text-base-font-color'
                                                    />
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </Flex>
                            ))}
                        </Flex>
                    </Col>
                </Row>
            </Flex>
        </Flex>
    );
};

const DiningDialog = ({ data, content, album, currentMusicWaveColor }: DiningDialogType) => {
    return (
        <Dialog data={data}>
            <ModalTemplate
                content={content}
                album={album}
                currentMusicWaveColor={currentMusicWaveColor}
            />
        </Dialog>
    );
};

export default DiningDialog;
