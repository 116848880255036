'use client';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import cn from 'classnames';
import style from './index.module.scss';

type ScrollViewProps = {
    children?: React.ReactNode;
    classname?: string;
    thumbColor?: string;
};

const ScrollView = ({ children, classname, thumbColor = '#fdfdfd', ...props }: ScrollViewProps) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const scrollTrackRef = useRef<HTMLDivElement>(null);
    const scrollThumbRef = useRef<HTMLDivElement>(null);
    const observer = useRef<ResizeObserver | null>(null);
    const [thumbHeight, setThumbHeight] = useState(20);
    const [scrollStartPosition, setScrollStartPosition] = useState<number | null>(null);
    const [initialScrollTop, setInitialScrollTop] = useState<number>(0);
    const [isDragging, setIsDragging] = useState(false);

    function handleResize(ref: HTMLDivElement, trackSize: number) {
        const { clientHeight, scrollHeight } = ref;
        setThumbHeight(Math.max((clientHeight / scrollHeight) * trackSize, 20));
    }

    const handleThumbPosition = useCallback(() => {
        if (!contentRef.current || !scrollTrackRef.current || !scrollThumbRef.current) {
            return;
        }
        const { scrollTop: contentTop, scrollHeight: contentHeight } = contentRef.current;
        const { clientHeight: trackHeight } = scrollTrackRef.current;
        if (trackHeight == thumbHeight) {
            setThumbHeight(0);
            scrollTrackRef.current.style.height = '0';
        }
        let newTop = (+contentTop / +contentHeight) * trackHeight;
        newTop = Math.min(newTop, trackHeight - thumbHeight);
        const thumb = scrollThumbRef.current;
        thumb.style.top = `${newTop}px`;
        thumb.style.backgroundColor = thumbColor;
    }, [thumbColor, thumbHeight]);

    const handleTrackClick = useCallback(
        (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            const { current: trackCurrent } = scrollTrackRef;
            const { current: contentCurrent } = contentRef;
            if (trackCurrent && contentCurrent) {
                const { clientY } = e;
                const target = e.target as HTMLDivElement;
                const rect = target.getBoundingClientRect();
                const trackTop = rect.top;
                const thumbOffset = -(thumbHeight / 2);
                const clickRatio = (clientY - trackTop + thumbOffset) / trackCurrent.clientHeight;
                const scrollAmount = Math.floor(clickRatio * contentCurrent.scrollHeight);
                contentCurrent.scrollTo({
                    top: scrollAmount,
                    behavior: 'smooth',
                });
            }
        },
        [thumbHeight],
    );

    const handleThumbMousedown = useCallback((e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setScrollStartPosition(e.clientY);
        if (contentRef.current) setInitialScrollTop(contentRef.current.scrollTop);
        setIsDragging(true);
    }, []);

    const handleThumbMouseup = useCallback(
        (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            if (isDragging) {
                setIsDragging(false);
            }
        },
        [isDragging],
    );

    const handleThumbMousemove = useCallback(
        (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            if (isDragging && contentRef?.current) {
                const { scrollHeight: contentScrollHeight, offsetHeight: contentOffsetHeight } =
                    contentRef?.current;

                const deltaY =
                    (e.clientY - (scrollStartPosition || 0)) * (contentOffsetHeight / thumbHeight);
                const newScrollTop = Math.min(
                    initialScrollTop + deltaY,
                    contentScrollHeight - contentOffsetHeight,
                );
                contentRef.current.scrollTop = newScrollTop;
            }
        },
        [initialScrollTop, isDragging, scrollStartPosition, thumbHeight],
    );

    useEffect(() => {
        if (contentRef?.current && scrollTrackRef?.current) {
            const ref = contentRef.current;
            const { clientHeight: trackSize } = scrollTrackRef.current;
            observer.current = new ResizeObserver(() => {
                handleResize(ref, trackSize);
            });
            handleThumbPosition();
            observer.current.observe(ref);
            ref.addEventListener('scroll', handleThumbPosition);
            return () => {
                observer.current?.unobserve(ref);
                ref.removeEventListener('scroll', handleThumbPosition);
            };
        }
    }, [handleThumbPosition]);

    useEffect(() => {
        document.addEventListener('mousemove', handleThumbMousemove);
        document.addEventListener('mouseup', handleThumbMouseup);
        document.addEventListener('mouseleave', handleThumbMouseup);
        return () => {
            document.removeEventListener('mousemove', handleThumbMousemove);
            document.removeEventListener('mouseup', handleThumbMouseup);
            document.removeEventListener('mouseleave', handleThumbMouseup);
        };
    }, [handleThumbMousemove, handleThumbMouseup]);

    return (
        <div className={style.scrollViewContainer}>
            <div className={cn(style.scrollViewContent, classname)} ref={contentRef} {...props}>
                {children}
            </div>
            <div className={style.scrollbarContainer}>
                <div
                    ref={scrollTrackRef}
                    className={cn(style.scrollbarTrack, classname)}
                    onClick={handleTrackClick}
                ></div>
                <div
                    ref={scrollThumbRef}
                    className={style.scrollbarThumb}
                    onMouseDown={handleThumbMousedown}
                    style={{
                        height: `${thumbHeight}px`,
                    }}
                ></div>
            </div>
        </div>
    );
};

export default ScrollView;
