'use client';
import React, { useEffect, useRef } from 'react';
import { AddToCalendarButton, AddToCalendarButtonType } from 'add-to-calendar-button-react';
import { WATrackerClickEvn } from '@/utils/wa';
import useWindowSize from '@/utils/useScreenSize';
import { mobileBreakPoint } from '@/utils/utils';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import { useTranslation } from '@/i18n/use-transition.client';

type addToCalendarProp = {
    data: {
        setting?: AddToCalendarButtonType;
        modalHeadline?: string;
        modaldescription?: string;
        isLink?: boolean;
        cardThemeColor?: string | undefined;
        clickEventID?: string;
        cardFontColor?: string;
        currentSecondaryBGColor?: string;
        currentSecondaryIconColor?: string;
    };
};

export const DummyData: addToCalendarProp['data'] = {
    setting: {
        name: 'Title',
        options: ['Apple', 'Google', 'Outlook.com'],
        dates: [
            {
                name: 'Reminder 1/3 to test the Add to Calendar Button',
                description:
                    'This is the first part to check the Add to Calendar Button script at [url]https://add-to-calendar-button.com/[/url]',
                startDate: 'today+3',
                startTime: '10:15',
                endDate: 'today+8',
                endTime: '23:30',
            },
            {
                name: 'Reminder 3/3 to test the Add to Calendar Button',
                description:
                    'This is the third part to check the Add to Calendar Button script at [url]https://add-to-calendar-button.com/[/url]',
                startDate: 'today+8',
                startTime: '09:00',
                endTime: '19:00',
            },
        ],
        // startDate: '2024-02-04',
        // endDate: '2024-02-04',
        // startTime: '10:15',
        // endTime: '23:30',
        // label:'Test', // Button label
        iCalFileName: 'Event-confirmed', // File export name
    },
};

const renderCalendarIcon = (
    node: HTMLDivElement,
    // Defaule Primary color
    bgColor = '#EAE219', //Backgroud color
    iconColor = '#001B34', //Icon color
) => {
    const calendarIcon = node.querySelector('svg.calendar-icon');
    if (node.querySelector('svg.calendar-icon')) {
        calendarIcon?.querySelector('circle')?.setAttribute('fill', bgColor);
        calendarIcon?.querySelectorAll('path')?.forEach((path) => {
            path?.setAttribute('fill', iconColor);
        });
        return;
    }

    const iconSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg'); //SVG note container
    const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle'); //Background
    const iconPath1 = document.createElementNS('http://www.w3.org/2000/svg', 'path'); //Icon path 1
    const iconPath2 = document.createElementNS('http://www.w3.org/2000/svg', 'path'); //Icon path 2

    iconSvg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    iconSvg.setAttribute('viewBox', '0 0 26 26');
    iconSvg.setAttribute('width', '26');
    iconSvg.setAttribute('height', '26');
    iconSvg.setAttribute('fill', 'none');
    iconSvg.classList.add('calendar-icon');

    circle.setAttribute('cx', '13');
    circle.setAttribute('cy', '13');
    circle.setAttribute('r', '13');
    circle.setAttribute('fill', bgColor);

    iconPath1.setAttribute('fill', iconColor);
    iconPath1.setAttribute('fill-rule', 'evenodd');
    iconPath1.setAttribute(
        'd',
        'M17.903 8.78H8.096a.228.228 0 0 0-.228.229v8.895c0 .126.102.228.228.228h9.807a.228.228 0 0 0 .229-.228V9.009a.228.228 0 0 0-.229-.228ZM8.096 7.87c-.63 0-1.14.51-1.14 1.14v8.895c0 .63.51 1.14 1.14 1.14h9.807c.63 0 1.14-.51 1.14-1.14V9.009c0-.63-.51-1.14-1.14-1.14H8.096Z',
    );
    iconPath1.setAttribute('clip-rule', 'evenodd');

    iconPath2.setAttribute('fill', iconColor);
    iconPath2.setAttribute('fill-rule', 'evenodd');
    iconPath2.setAttribute(
        'd',
        'M10.035 6.956c.252 0 .456.204.456.456v1.825a.456.456 0 1 1-.912 0V7.412c0-.252.204-.456.456-.456ZM15.965 6.956c.252 0 .456.204.456.456v1.825a.456.456 0 1 1-.912 0V7.412c0-.252.204-.456.456-.456ZM18.246 11.632H7.526v-.913h10.72v.913Z',
    );
    iconPath2.setAttribute('clip-rule', 'evenodd');

    iconSvg.appendChild(circle);
    iconSvg.appendChild(iconPath1);
    iconSvg.appendChild(iconPath2);

    iconSvg.style.marginLeft = '10px';

    return node.appendChild(iconSvg);
};

const AddToCalendar = ({ data }: addToCalendarProp) => {
    const atcContainerRef: any = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const isMobile = useWindowSize().width < mobileBreakPoint;
    const globalStore = useGlobalStore();
    // console.log('calendar---data----', data);
    // console.log('calendar---globalStore----', globalStore);
    const fontColor = globalStore.currentTheme?.fontColor;
    const secondaryButtonBGColor = globalStore.currentTheme?.secondaryButtonBGColor;
    const secondaryButtonIconColor = globalStore.currentTheme?.secondaryButtonIconColor;

    useEffect(() => {
        const atcBtnObserver = new IntersectionObserver((mutations) => {
            const atcBtn = atcContainerRef.current
                ?.querySelector('add-to-calendar-button')
                ?.shadowRoot?.querySelector('.atcb-button');
            if (atcBtn) {
                data.isLink
                    ? renderCalendarIcon(atcBtn, secondaryButtonBGColor)
                    : renderCalendarIcon(
                          atcBtn,
                          secondaryButtonBGColor || '#001B34',
                          secondaryButtonIconColor,
                      );
                atcBtn.style.border = '0';
                atcBtn.style.margin = '0';
                atcBtn.style.padding = isMobile ? '14.5px 8px' : '16px 26px';
                atcBtn.style.borderRadius = '4px';
            }
        });
        const lastEventObserver = new MutationObserver((mutations) => {
            mutations.forEach((record: any) => {
                if (record.type === 'attributes') {
                    const lastEventAttr = record.target.getAttribute(record.attributeName);
                    const atcBtnShadow =
                        atcContainerRef.current?.querySelector(
                            'add-to-calendar-button',
                        )?.shadowRoot;
                    const atcBtn = atcBtnShadow?.querySelector('.atcb-button');

                    if (data.isLink) {
                        if (lastEventAttr.includes('openList')) {
                            WATrackerClickEvn(data.clickEventID);
                            atcBtn.style.backgroundColor = '#fff';
                            atcBtn.style.color = '#000';
                        }
                        if (lastEventAttr.includes('closeList')) {
                            atcBtn.style.backgroundColor = 'transparent';
                            atcBtn.style.removeProperty('color');
                        }
                    } else {
                        if (lastEventAttr.includes('openList')) {
                            WATrackerClickEvn(data.clickEventID);
                            atcBtn.style.backgroundColor = data?.cardThemeColor || '#EAE219';
                        }
                        if (lastEventAttr.includes('closeList')) {
                            atcBtn.style.removeProperty('background-color');
                        }
                    }
                    if (lastEventAttr.includes('openCalendarLink')) {
                        const modalHost =
                            document.querySelector('#atcb-btn-1-modal-host')?.shadowRoot;
                        setTimeout(() => {
                            const modalHeadline = modalHost?.querySelector('.atcb-modal-headline');
                            const modalDescription = modalHost?.querySelector(
                                '.atcb-modal-box > .atcb-modal-content',
                            );
                            const modalCloseBtn: any = modalHost?.querySelector(
                                '.atcb-modal-btn.btn-small',
                            );

                            if (modalHeadline && modalDescription) {
                                modalHeadline.innerHTML = data.modalHeadline || '';
                                modalDescription.innerHTML = data.modaldescription || '';
                                modalCloseBtn.innerHTML = t('close');
                            }
                        }, 0);
                    }
                }
            });
        });

        if (atcContainerRef.current) {
            lastEventObserver.observe(atcContainerRef.current, {
                attributes: true,
                childList: true,
                subtree: true,
            });
            atcBtnObserver.observe(atcContainerRef.current);

            return () => {
                lastEventObserver.disconnect();
                atcBtnObserver.disconnect();
            };
        }
    }, [
        data.cardThemeColor,
        data.clickEventID,
        data.isLink,
        data.modalHeadline,
        data.modaldescription,
        secondaryButtonBGColor,
        secondaryButtonIconColor,
        isMobile,
    ]);
    // const { t } = useTranslation();

    const btnStyle = `
        ${
            data.isLink
                ? `
            --btn-background: transparent;
            --btn-hover-background: transparent;
            --btn-hover-shadow: none;
            --btn-text: ${data.cardFontColor || fontColor};
            --btn-hover-text: #ffffffcc;`
                : `
            --btn-text: ${data.cardFontColor || fontColor};
            --btn-background: ${data?.cardThemeColor};
            --btn-hover-background: ${data?.cardThemeColor}CC;
        `
        }
        --font: 'Manrope', sans-serif;
        --base-font-size-l: ${isMobile ? '14px' : '16px'};
        --base-font-size-m: ${isMobile ? '14px' : '16px'};
        --base-font-size-s: ${isMobile ? '14px' : '16px'};
        --btn-font-weight: 800;
        --btn-border: transparent;
        --btn-shadow: none;`;

    return (
        <div ref={atcContainerRef}>
            <AddToCalendarButton
                styleLight={btnStyle}
                timeZone='Asia/Hong_Kong'
                language={globalStore.lang === 'zh-HK' ? 'zh' : 'en'}
                trigger='click'
                hideBranding
                hideIconButton
                {...data.setting}
            />
        </div>
    );
};

export default AddToCalendar;
