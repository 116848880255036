import * as React from 'react';

type iconProps = {
    iconColor?: string;
};

const QuoteIcon = ({ iconColor = '#EAE219' }: iconProps) => (
    <svg xmlns='http://www.w3.org/2000/svg' width={82} height={58} fill='none'>
        <path
            fill={iconColor}
            fillRule='evenodd'
            d='M1.747 30.465C3.36 25.714 7.903 17.258 16.577 5.55 20.522.793 21.312 0 24.074 0h4.737c1.184.264 2.684 1.507.79 4.361-1.846 2.78-6.566 10.614-8.001 15.252 9.206 1.298 16.289 9.24 16.289 18.846 0 10.51-8.482 19.031-18.945 19.031C8.482 57.49 0 48.97 0 38.46c0-2.855.626-5.564 1.747-7.994ZM45.637 30.464c1.612-4.75 6.156-13.206 14.83-24.913C64.412.793 65.201 0 67.964 0h4.736c1.184.264 2.684 1.507.79 4.361-1.845 2.78-6.565 10.614-8 15.252 9.205 1.298 16.289 9.24 16.289 18.846 0 10.51-8.482 19.031-18.945 19.031-10.463 0-18.945-8.52-18.945-19.031 0-2.855.626-5.564 1.747-7.995Z'
            clipRule='evenodd'
        />
    </svg>
);
export default QuoteIcon;
