'use client';
import React, { useEffect, useRef } from 'react';
import cn from 'classnames';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Swiper, SwiperSlide, useSwiper, SwiperProps, SwiperRef } from 'swiper/react';
// @ts-ignore
import { Navigation, Pagination, EffectCoverflow, Autoplay } from 'node_modules/swiper/swiper.esm';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

import Heading from '@componentsHeading';
import Text from '@componentsText';
import { Col, Flex, Image, Row } from 'antd';
import { ImagePreviewType } from 'rc-image/lib/Image';

import { DiningCardType, OverlayCard, overlayCardItemProp } from '@componentsTemplate/Card';
import { isTimeFrame, withBasePath, withFullPath } from '@/utils/utils';
import ImagePreview from '@componentsImagePreview';
import AddToCalendar from '@componentsAddToCalendar';
import CustButton, { GeneralButtonType } from '@componentsButton';
import {
    AlbumItemFragment,
    PartyCardListFragment,
    PartyInformationFragment,
    VenueItemFragment,
} from '@/@types/generated/sitecore';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import QuoteIcon from '../SVG/QuoteIcon';
import { format } from 'date-fns';
import { useTranslation } from '@/i18n/use-transition.client';
import RichText from '../RichText';
import { SwiperModule } from 'swiper/types';
import { getButtonLinkWithCID, WATrackerClickEvn } from '@/utils/wa';

type settings = {
    modules?: [];
    loop?: string;
    slidesPerView?: number;
};

type imageItem = {
    id: number;
    src?: string;
    alt?: string;
    width?: number;
    height?: number;
    videoSrc?: string;
    mediaType?: 'Photo' | 'Video';
    mediaCaption?: string;
};

export type cardItem = {
    id: number;
    cover: {
        src: string;
        alt: string;
    };
    title: string;
    subtitle: string;
    btn: {
        label: string;
        url: string;
    };
    status: 'enable' | 'disable' | 'current';
    features: {
        icon?: string;
        title?: string;
        description?: string;
    }[];
};

type cardSlider = {
    data: cardItem[];
};

type testimonialSlideItem = {
    bgImage: string;
    testimonials: {
        id: number;
        content: string;
        name: string;
    }[];
};

type testimonialSlider = {
    data: testimonialSlideItem;
};

type imageSlider = {
    data: imageItem[];
    canPreview?: boolean;
    className?: string;
    rootClassName?: string;
    previewProps?: ImagePreviewType;
};

type overlayCardSlider = {
    data: overlayCardItemProp['data'][];
};

type sliderProp = {
    className?: string;
    slideTo?: number;
    children: any;
    settings?: SwiperProps;
    showPagination?: boolean;
    currentThemeColor?: string | undefined;
    modules?: SwiperProps['modules'];
};

const CardSliderIcon: {
    [key: string]: any;
} = {
    game: '/images/schedule/i-game.png',
    music: '/images/schedule/i-musician.png',
    performance: '/images/schedule/i-performance.png',
};

const CardSliderEmpty = () => {
    const { t } = useTranslation();
    return (
        <Flex vertical gap={8} justify={'center'} align={'center'} className={'opacity-50 mt-20'}>
            <Flex gap={24}>
                {['game', 'music', 'performance'].map((i, index: number) => (
                    <Image
                        key={index}
                        width={24}
                        height={24}
                        preview={false}
                        src={withBasePath(`${CardSliderIcon[i]}`)}
                        alt={''}
                    />
                ))}
            </Flex>
            <Heading level={6} text={t('comingSoon')} className='!text-base-font-color' />
        </Flex>
    );
};

export const OverlayCardSlder = ({ data, currentThemeColor, currentH2Color }: any) => {
    // console.log('data---=-=======', data);
    return data.map((item: VenueItemFragment, index: number) => (
        <SwiperSlide key={index} className={'mb-12 !h-[306px] xmd:!h-[415px]'}>
            <OverlayCard
                data={item}
                style={{
                    containerMinHeightSm: 'h-full',
                    borderSm: 'rounded-[10px]',
                }}
                currentThemeColor={currentThemeColor}
            >
                <DiningCardType
                    currentThemeColor={currentThemeColor}
                    data={item}
                    currentH2Color={currentH2Color}
                />
            </OverlayCard>
        </SwiperSlide>
    ));
};

export const SimpleImageSlider = ({
    data,
    canPreview = true,
    className,
    rootClassName,
    previewProps,
}: imageSlider) => {
    return data.map((image) => (
        <SwiperSlide key={image.id}>
            <ImagePreview
                setting={{
                    className: cn('w-full h-full object-contain', className),
                    src: image.src || '',
                    alt: image.alt,
                    rootClassName,
                }}
                previewProps={previewProps}
                canPreview={canPreview}
                mediaOption={{
                    mediaType: image.mediaType,
                    videoSrc: image.videoSrc,
                    mediaCaption: image.mediaCaption,
                }}
            />
        </SwiperSlide>
    ));
};

export const AlbumSimpleImageSlider = (data: Array<AlbumItemFragment>) => {
    return data.map((image) => (
        <SwiperSlide key={image.name}>
            <div className={'mr-[10px]'}>
                <ImagePreview
                    setting={{
                        className: 'w-full h-full xmd:h-[140px] object-contain rounded-md',
                        src: image.contentImage?.src,
                    }}
                    canPreview={true}
                    mediaOption={{
                        mediaType: image.mediaType?.value,
                        videoSrc: image.videoSrc?.value,
                        mediaCaption: image.mediaCaption?.value,
                    }}
                />
            </div>
        </SwiperSlide>
    ));
};

/** parties-detail-partyCard */
export const CardSlider = (
    partyCardList: PartyCardListFragment[],
    cardThemeColor: {
        themeColor: string | undefined;
        h2Color: string | undefined;
        partyThemeColor: string | undefined;
        partyH2Color: string | undefined;
    },
) => {
    const globalStore = useGlobalStore();
    const { t } = useTranslation();
    // console.log('partyCardList----', partyCardList);
    const currentThemeColor = cardThemeColor?.partyThemeColor
        ? cardThemeColor?.partyThemeColor
        : cardThemeColor?.themeColor;

    const cardBorder =
        'absolute rounded-[10px] bg-secondary-bg-color inset-x-0 top-6 bottom-0 -z-1';
    const normalCard = '[&+div]:opacity-50 border-2 border-solid border-[#36536F]';
    const activeCard = 'border-2 border-solid border-[#EAE219] shadow-[0_0_10px_0_#EAE219]';
    // const statusVariants: any = {
    //     'coming-soon': 'border-2 border-solid border-[#36536F]',
    //     album: '[&+div]:opacity-50 border-2 border-solid border-[#36536F]',
    //     active: 'border-2 border-solid border-[#EAE219] shadow-[0_0_10px_0_#EAE219]',
    // };
    const albumPath = (url: string) => {
        const regex = /\/Parties\/(.+?)\/PhotoAlbums\/(.+)/;
        const matches = url.match(regex);

        return matches && matches.length === 3 ? `/album/${matches[1]}/${matches[2]}` : '';
    };

    return partyCardList?.map((card, index) => {
        const isCurrent = isTimeFrame(
            card?.partyDateTime?.dateValue,
            card?.partyEndDateTime?.dateValue,
        );
        const cardStartDate = format(new Date(card.partyDateTime?.dateValue || 0), 'yyyy-MM-dd');
        const cardStartDateTime = format(new Date(card.partyDateTime?.dateValue || 0), 'HH:mm:ss');
        const cardEndDate = format(new Date(card.partyEndDateTime?.dateValue || 0), 'yyyy-MM-dd');
        const cardEndDateTime = format(new Date(card.partyEndDateTime?.dateValue || 0), 'HH:mm:ss');
        return (
            <SwiperSlide
                key={card.name + index}
                className={'h-auto !mb-16 xmd:mb-0'}
                style={{ height: 'auto' }}
            >
                <div
                    className={cn(cardBorder, isCurrent ? activeCard : normalCard)}
                    style={{
                        borderColor: (isCurrent && currentThemeColor) || '',
                        boxShadow: (isCurrent && `0px 0px 10px 0px ${currentThemeColor}`) || '',
                    }}
                ></div>
                <Flex vertical justify={'center'} align={'center'} className='relative pb-8'>
                    <div className={'w-[90%]'}>
                        <ImagePreview
                            setting={{
                                className: cn('rounded-[10px]'),
                                src: withFullPath(card.partyCardImage?.src),
                                alt: '',
                            }}
                        />
                    </div>
                    <Heading
                        level={6}
                        text={card.partyCardTitle?.value}
                        className='mt-6 !text-[26px] w-[90%] text-center'
                        style={{ color: cardThemeColor?.partyH2Color || cardThemeColor?.h2Color }}
                    />
                    <Text
                        level='body2'
                        text={card.partyCardText?.value}
                        className='mt-2 text-base-font-color w-[90%] text-center'
                    />
                    <div className='mt-6'>
                        {card.partyCardState?.targetItem?.name != 'album' ? (
                            <AddToCalendar
                                data={{
                                    setting: {
                                        label: t('atcbLabel'),
                                        options: ['Apple', 'Google', 'Outlook.com'],
                                        name: card.partyCardTitle?.value || '',
                                        description: '',
                                        startDate: cardStartDate,
                                        startTime: cardStartDateTime,
                                        endDate: cardEndDate,
                                        endTime: cardEndDateTime,
                                        iCalFileName:
                                            card.partyCardTitle?.value || 'Event-confirmed', // File export name
                                    },
                                    modalHeadline: t('atcbLabel'),
                                    modaldescription: '',
                                    cardThemeColor: currentThemeColor,
                                    // cardFontColor:
                                    //     cardThemeColor?.partyFontColor ||
                                    //     cardThemeColor?.fontColor ||
                                    //     '#000000',
                                    clickEventID: 'RnE/HW_ClickAddToCalendar_Event-Card',
                                }}
                            />
                        ) : (
                            <CustButton
                                data={{
                                    buttonLink: getButtonLinkWithCID(
                                        albumPath(card.albumPath?.targetItem?.path || ''),
                                        `${globalStore.pageID}_Party-detail`,
                                    ),
                                    setting: {
                                        onClick: () => {
                                            WATrackerClickEvn('RnE/HW_ClickBanner_Party-detail');
                                        },
                                    },
                                }}
                            >
                                <GeneralButtonType label={t('viewAlbum')} icon={'arrowRightBK'} />
                            </CustButton>
                        )}
                    </div>
                    <Flex vertical gap={17} className='mx-5 mt-5'>
                        {card.partyCardState?.targetItem?.name !== 'coming-soon' ? (
                            card.partyCardItem.map((feature, index: number) => (
                                <Flex gap={10} align='start' key={index}>
                                    <div>
                                        <Image
                                            width={24}
                                            height={24}
                                            preview={false}
                                            src={withBasePath(
                                                `${CardSliderIcon[feature.iconImage?.targetItem?.name || '']}`,
                                            )}
                                            alt={''}
                                        />
                                    </div>
                                    <Flex vertical>
                                        <Heading
                                            level={7}
                                            className='!text-base-font-color pt-[2px]'
                                            text={feature.rowTitle?.value}
                                        />
                                        <Text
                                            level='body3'
                                            text={feature.rowContent?.value}
                                            className='mt-2 text-base-font-color'
                                        />
                                    </Flex>
                                </Flex>
                            ))
                        ) : (
                            <CardSliderEmpty />
                        )}
                    </Flex>
                </Flex>
            </SwiperSlide>
        );
    });
};

export const CardSlideWithDate = ({ data }: { data: PartyInformationFragment[] }) => {
    const globalStore = useGlobalStore();
    const { t } = useTranslation();
    const currentTheme = globalStore.currentTheme;
    return data.map((party, index) => {
        const currentDate = new Date().getTime();
        const isCurrent = isTimeFrame(
            party?.startDatetime?.dateValue,
            party?.endDatetime?.dateValue,
        );

        const isPastParty =
            party?.endDatetime?.dateValue && party?.endDatetime?.dateValue < currentDate;
        return (
            <SwiperSlide key={index}>
                {({ isActive, isNext, isPrev }) => {
                    return (
                        <Flex
                            vertical
                            className={cn(
                                'h-full opacity-25',
                                data.length > 1 && (isNext || isPrev) && '!opacity-50',
                                isActive && '!opacity-100',
                            )}
                        >
                            {party.partyImage?.src && (
                                <ImagePreview
                                    setting={{
                                        src: withFullPath(party.partyImage?.src),
                                        alt: '',
                                        rootClassName: '!block',
                                        className: 'rounded-t-[5px]',
                                    }}
                                />
                            )}
                            <Flex
                                vertical
                                className={cn(
                                    'pt-5 pb-[22px] px-5 bg-[#0D263E] flex-grow gap-4 rounded-b-[5px] border-2 border-[#36536F]',
                                )}
                            >
                                <Flex className={'justify-between gap-3'}>
                                    <Heading
                                        level={4}
                                        text={party.partyName?.value}
                                        style={{
                                            wordBreak: 'break-word',
                                        }}
                                        className={cn('text-base-font-color')}
                                    />
                                    {isCurrent ? (
                                        <Heading
                                            level={6}
                                            text={t('homePartyCardCurrent')}
                                            className={cn(
                                                'text-subscribe-btn border-2 border-subscribe-btn !rounded-2xl px-4 py-1 h-fit whitespace-nowrap',
                                            )}
                                            style={{
                                                color: currentTheme?.themeColor,
                                                borderColor: currentTheme?.themeColor,
                                            }}
                                        />
                                    ) : (
                                        isPastParty && (
                                            <Heading
                                                level={6}
                                                text={t('homePartyCardPast')}
                                                className={cn(
                                                    'text-subscribe-btn border-2 border-subscribe-btn !rounded-2xl px-4 py-1 h-fit whitespace-nowrap',
                                                )}
                                                style={{
                                                    color: currentTheme?.themeColor,
                                                    borderColor: currentTheme?.themeColor,
                                                }}
                                            />
                                        )
                                    )}
                                </Flex>
                                <Flex className={'justify-between gap-3'}>
                                    <Heading
                                        level={5}
                                        text={party.partyDateText?.value}
                                        className={'text-black bg-[#EAE219] w-fit px-2 py-[2px]'}
                                        style={{
                                            color: currentTheme?.fontColor,
                                            backgroundColor: currentTheme?.themeColor,
                                        }}
                                    />
                                    {party.homeCardCTAButtonText?.value && (
                                        <CustButton
                                            data={{
                                                setting: {
                                                    type: 'link',
                                                    onClick: () => {
                                                        WATrackerClickEvn(
                                                            'RnE/HW_ClickBanner_Feature-Highlight',
                                                        );
                                                    },
                                                },
                                                buttonLink: getButtonLinkWithCID(
                                                    party.homeCardCTAButtonUrl?.value,
                                                    `${globalStore.pageID}_Feature-Highlight`,
                                                ),
                                            }}
                                        >
                                            <GeneralButtonType
                                                label={party.homeCardCTAButtonText?.value || ''}
                                                icon={'arrowRightPrimary'}
                                                className={'!text-base-font-color'}
                                            />
                                        </CustButton>
                                    )}
                                </Flex>
                            </Flex>
                        </Flex>
                    );
                }}
            </SwiperSlide>
        );
    });
};

export const TestimonialSlider = ({ data }: testimonialSlider) => {
    return data.testimonials.map((item) => (
        <SwiperSlide key={item.id}>
            <Row gutter={[24, 12]} className={'pb-12 justify-center'}>
                <Col lg={{ span: 4 }}>
                    <QuoteIcon />
                </Col>
                <Col lg={{ span: 20 }}>
                    <Flex vertical gap={16}>
                        <Heading level={3} text={item.content} />
                        <Text
                            level={'body1'}
                            text={item.name}
                            className='slider-testimonial-item__name'
                        />
                    </Flex>
                </Col>
            </Row>
        </SwiperSlide>
    ));
};

export const HomeTestimonialSlider = ({ data, themeColor }: any) => {
    // console.log('data-----', data);
    return data.map(
        (
            item: {
                template: { name: string };
                testimonialContent?: { rendered?: string };
                contributor?: { rendered?: string };
            },
            index: number,
        ) => (
            <SwiperSlide key={index}>
                <Row gutter={[24, 12]} className={'pb-12 justify-center'}>
                    <Col lg={{ span: 4 }} style={{ display: 'flex', justifyContent: 'right' }}>
                        <QuoteIcon iconColor={themeColor} />
                    </Col>
                    <Col lg={{ span: 20 }}>
                        <Flex vertical gap={16}>
                            <RichText html={item.testimonialContent?.rendered || ''} />
                            <RichText
                                className='slider-testimonial-item__name'
                                html={item.contributor?.rendered || ''}
                            />
                        </Flex>
                    </Col>
                </Row>
            </SwiperSlide>
        ),
    );
};

export const SliderControlButton = (props: {
    className?: string;
    onPrev?: () => void;
    onNext?: () => void;
}) => {
    const swiper = useSwiper();
    const iconStyle = 'text-3xl mx-5 text-base-font-color';
    return (
        <Flex justify='center' className={cn('mt-6', props.className)}>
            <ArrowLeftOutlined
                className={cn(iconStyle)}
                onClick={() => {
                    swiper.slidePrev();
                    props.onPrev && props.onPrev();
                }}
            />
            <ArrowRightOutlined
                className={cn(iconStyle)}
                onClick={() => {
                    swiper.slideNext();
                    props.onNext && props.onNext();
                }}
            />
        </Flex>
    );
};

export const Slider = ({
    children,
    settings,
    className,
    slideTo = 0,
    showPagination = true,
    currentThemeColor,
    modules,
}: sliderProp) => {
    const swiperRef = useRef<SwiperRef>(null);
    const globalStore = useGlobalStore();

    const themeColor =
        globalStore.globalSettingData?.masterColorCode?.targetItem?.themeColorCode?.value || '';

    // useEffect(() => {
    //     // Slide to target slide
    //     // console.log('slideTo=========', slideTo);
    //     swiperRef.current?.swiper.slideTo(slideTo);
    // });

    const style = {
        '--swiper-pagination-color': currentThemeColor ? currentThemeColor : themeColor,
        '--swiper-pagination-bullet-inactive-color': '#999999',
        '--swiper-pagination-bullet-inactive-opacity': '1',
        '--swiper-pagination-bullet-size': '16px',
        '--swiper-pagination-bullet-horizontal-gap': '6px',
        color: '#fff',
    } as React.CSSProperties;

    const setModules = showPagination
        ? ([Navigation, Pagination, EffectCoverflow, settings?.autoplay && Autoplay].filter(
              Boolean,
          ) as SwiperModule[])
        : [Navigation, EffectCoverflow];

    const sliderOptions = {
        modules: modules || setModules,
        spaceBetween: 20,
        ...settings,
    };

    // Handle Slide is not Loop
    const handleSlideIsNotLoop = (swiperRef: any) => {
        const $target = swiperRef.current;
        const $swiperRef = $target?.swiper;
        const isLoop = $swiperRef?.passedParams.loop;
        const $prevArrowIcon = swiperRef?.current.querySelectorAll('.anticon-arrow-left');
        const $nextArrowIcon = swiperRef?.current.querySelectorAll('.anticon-arrow-right');
        if (isLoop) return;

        const classes = ['opacity-50', 'pointer-events-none', 'touch-action-none'];

        // Handle Prev Arrow Button disabled when the slider is at the beginning
        // console.log('$swiperRef?.isBeginning', $swiperRef?.isBeginning);
        if ($swiperRef?.isBeginning) {
            $prevArrowIcon[0]?.classList.add(...classes);
        } else {
            $prevArrowIcon[0]?.classList.remove(...classes);
        }

        if ($swiperRef?.isEnd) {
            $nextArrowIcon[0]?.classList.add(...classes);
        } else {
            $nextArrowIcon[0]?.classList.remove(...classes);
        }

        // const prevArrowBtn = $swiperRef?.isBeginning
        //     ? $prevArrowIcon[0]?.classList.add(...classes)
        //     : $prevArrowIcon[0]?.classList.remove(...classes);

        // // Handle Prev Arrow Button disabled when the slider is at the end
        // const nextArrowBtn = $swiperRef?.isEnd
        //     ? $nextArrowIcon[0]?.classList.add(...classes)
        //     : $nextArrowIcon[0]?.classList.remove(...classes);
    };

    useEffect(() => {
        // Slide to target slide
        if (settings?.loop) {
            swiperRef.current?.swiper.slideToLoop(slideTo);
            return;
        }
        swiperRef.current?.swiper.slideTo(slideTo);
    }, [slideTo]);

    return (
        <div className={cn('', className)}>
            <Swiper
                {...sliderOptions}
                ref={swiperRef}
                style={style}
                onAfterInit={() => {
                    handleSlideIsNotLoop(swiperRef);
                }}
                onSlideChange={() => {
                    handleSlideIsNotLoop(swiperRef);
                }}
            >
                {children}
            </Swiper>
        </div>
    );
};

export { SwiperSlide as Slide };
