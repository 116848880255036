'use client';

import React, { useRef, useState } from 'react';
import cn from 'classnames';
import { EnvironmentOutlined, RightCircleFilled, CloseOutlined } from '@ant-design/icons';

import Heading from '@componentsHeading';
import Text from '@componentsText';
import { Button, Flex } from 'antd';
import { mobileBreakPoint, withBasePath, withFullPath } from '@/utils/utils';
import useWindowSize from '@utils/useScreenSize';
import CustButton, { GeneralButtonType } from '@/components/Button';
import { isDesktop, isMobile as isMobileDevice, isTablet } from 'react-device-detect';
import ScrollView from '@componentsTemplate/ScrollView';
import DiningDialog from '@/components/PageDiningSingleBlock/Dialog';
import { AlbumListType } from '@/components/PageDiningSingleBlock';
import { VenueItemFragment } from '@/@types/generated/sitecore';
import { WATrackerClickEvn, getButtonLinkWithCID } from '@/utils/wa';
import RichText from '@/components/RichText';
import { useTranslation } from '@/i18n/use-transition.client';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import styles from './index.module.scss';

type generalType = {
    data: {
        title: string;
        description: string;
    };
    style?: {
        contentMaxHeightMd?: string;
        contentMaxHeightSm?: string;
    };
};

type generalWithBtnType = {
    data: {
        title: string;
        description: string;
        btn: {
            label: string;
            url: string;
        };
    };
};

type diningType = {
    data: {
        catagory: string;
        title: string;
        description: string;
        items: string[];
        btn: {
            label: string;
            url: string;
        };
    };
};

export type overlayCardItemProp = {
    data: {
        className?: string;
        cover?: {
            src: string;
            alt: string;
        };
        backgroundColor?: string;
        catagory?: string;
        title?: string;
    };
    children: React.ReactNode;
    isTitleAppeare?: boolean;
    style?: {
        containerMinHeightMd?: string;
        containerMinHeightSm?: string;
        borderMd?: string;
        borderSm?: string;
    };
};

export const DiningCardType = ({
    data,
    currentThemeColor,
    currentH2Color,
}: {
    data: VenueItemFragment;
    currentThemeColor: string;
    currentH2Color?: string;
}) => {
    // console.log('data--=-=======', data);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation();

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const globalStore = useGlobalStore();

    const venueAlbumList = data?.venueAlbums?.map((albumItem: any) => {
        if (albumItem.template.name !== 'VenueAlbumFolder') return [{ images: [] }];
        return {
            images: albumItem.albumList.map((imageItem: any, index: number) => {
                return {
                    id: index,
                    src: imageItem.contentImage?.src,
                    mediaType: imageItem.mediaType?.value,
                    mediaCation: imageItem.mediaCaption?.value,
                    videoSrc: imageItem.videoSrc?.value,
                };
            }),
        };
    }) || [{ images: [] }];

    const album = (
        venueAlbumList.every(Boolean) ? venueAlbumList[0] : [{ images: [] }]
    ) as AlbumListType;
    return (
        <>
            <Flex vertical gap={16} className={styles.venueDescriptionWrap}>
                <Flex vertical gap={8}>
                    <Text
                        level={'subtitle1'}
                        text={data?.venueMealType?.value}
                        className={'opacity-50 !text-white'}
                    />
                    <Heading
                        style={{ color: currentH2Color }}
                        level={5}
                        text={data?.venueName?.value}
                    />
                </Flex>
                <RichText
                    className={cn(
                        'text-body3 leading-body3 max-h-[150px] xmd:max-h-[250px] overflow-y-auto',
                        // styles.venueDescriptionWrap,
                    )}
                    html={data?.venueDescription?.rendered || ''}
                    style={{ '--scrollbar-thumb-color': currentThemeColor } as any}
                />
            </Flex>
            <div className={'flex-grow'}></div>
            <CustButton
                data={{
                    setting: {
                        type: 'link',
                        onClick: () => {
                            WATrackerClickEvn('RnE/HW_ClickBanner_Feature-Highlight-with-slider');
                            if (data?.venueLinkType?.value === 'Popup') {
                                setIsModalOpen(true);
                            }
                        },
                    },
                    buttonLink: getButtonLinkWithCID(
                        data?.venueLink?.value,
                        `${globalStore.pageID}_Feature-Highlight-with-slider`,
                    ),
                }}
                // currentMusicWaveColor={currentMusicWaveColor}
            >
                <GeneralButtonType
                    label={t('viewDetail')}
                    icon={'arrowRightPrimary'}
                    // iconColor={currentThemeColor}
                    // iconColor={currentSecondaryIconColor}
                    // iconBGColor={currentSecondaryBGColor}
                    className='!text-base-font-color'
                />
            </CustButton>
            <DiningDialog
                data={{
                    setting: {
                        open: isModalOpen,
                        onCancel: handleCancel,
                        width: 800,
                    },
                    currentThemeColor,
                }}
                // currentMusicWaveColor={currentMusicWaveColor}
                content={data}
                album={album}
            />
        </>
    );
};
export const VenueDiningCardType = ({ data, currentThemeColor }: any) => {
    const { t } = useTranslation();
    // console.log('VenueDiningCardType********', data);
    return (
        <>
            <Flex vertical gap={16}>
                <Flex vertical gap={8}>
                    <Text
                        level={'subtitle1'}
                        text={data.facilities?.value}
                        className={'opacity-50'}
                    />
                    <Heading level={5} text={data.venueName?.value} />
                </Flex>
                <RichText
                    className='text-body3 leading-body3'
                    html={data.venueDescription?.rendered || ''}
                />

                <Flex gap={10} align={'flex-start'}>
                    <EnvironmentOutlined />
                    <Text level='body3' text={''} />
                </Flex>
            </Flex>
            <div className={'flex-grow'}></div>
            <CustButton
                data={{
                    buttonLink: '',
                }}
            >
                <GeneralButtonType
                    label={t('viewDetail')}
                    icon={'arrowRightBK'}
                    iconColor={currentThemeColor}
                />
            </CustButton>
        </>
    );
};

export const GeneralCardType = ({ data, style }: generalType) => {
    return (
        <Flex align={'center'} className={'h-full'}>
            <Flex vertical gap={16} className={'px-5'}>
                <Heading level={5} text={data.title} className={'text-[#EAE219]'} />
                <ScrollView
                    classname={cn(style?.contentMaxHeightMd, style?.contentMaxHeightSm)}
                    thumbColor='#EAE219'
                >
                    <Text level={'body2'} text={data.description} />
                </ScrollView>
            </Flex>
        </Flex>
    );
};

export const GeneralWithBtnCardType = ({ data }: generalWithBtnType) => {
    const windowSize = useWindowSize();
    return (
        <Flex align={'center'} className={'h-full'}>
            <Flex vertical align={'center'} gap={16}>
                <Flex vertical gap={16} className={'px-5'}>
                    <Heading level={5} text={data.title} className={'text-[#EAE219]'} />
                    {windowSize.width >= mobileBreakPoint ? (
                        <Text level={'body2'} text={data.description} />
                    ) : (
                        <></>
                    )}
                </Flex>
                <CustButton
                    data={{
                        setting: { href: withBasePath(data.btn.url), type: 'link' },
                    }}
                >
                    <GeneralButtonType
                        label={data.btn.label}
                        icon={'arrowRightPrimary'}
                        // iconColor={currentThemeColor}
                        className='!text-base-font-color'
                    />
                </CustButton>
            </Flex>
        </Flex>
    );
};

export const OverlayCard = ({
    data,
    children,
    isTitleAppeare = true,
    style,
    currentThemeColor,
}: any) => {
    // console.log('datadatadata=====----------', data);
    const containerMinHeightMd = style?.containerMinHeightMd || '';
    const containerMinHeightSm = style?.containerMinHeightSm || '';
    const borderMd = style?.borderMd || 'rounded';
    const borderSm = style?.borderSm || '';
    const [isVisiable, setIsVisable] = useState(false);
    const cardOverlayRef = useRef<HTMLDivElement>(null);
    const cardCloseIconRef = useRef<HTMLDivElement>(null);

    const bgStyle = data?.venueHeroImage?.src && {
        backgroundImage: `url(${withFullPath(data?.venueHeroImage?.src)})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
    };

    const isMobile = useWindowSize().width < mobileBreakPoint || isTablet || isMobileDevice;

    return (
        <div
            className={cn(
                borderMd,
                borderSm,
                containerMinHeightMd,
                containerMinHeightSm,
                'relative',
            )}
            style={bgStyle}
        >
            <div
                className={cn(
                    borderMd,
                    borderSm,
                    'absolute bottom-0 h-auto w-full bg-gradient-to-t from-[#000] to-[rgba(0, 0, 0, 0.00)] px-5 py-6 z-0',
                )}
            >
                <Flex vertical gap={8}>
                    <Text
                        level={'subtitle1'}
                        text={data?.venueMealType?.value}
                        className={'opacity-50 !text-white'}
                    />
                    <Heading
                        className={'!text-base-font-color'}
                        level={5}
                        text={data?.venueName?.value}
                    />
                </Flex>
            </div>
            <Flex
                onClick={() => {
                    if (isMobile) setIsVisable(true);
                }}
                ref={cardOverlayRef}
                className={cn(
                    isVisiable
                        ? 'transition opacity-100 duration-300 ease-in-out'
                        : 'transition opacity-0 duration-300 ease-in-out',
                    !isMobile ? 'hover:opacity-100' : '',
                    'relative h-full w-full overflow-hidden bg-primary-bg-color/20 bg-fixed',
                )}
            >
                <Flex
                    vertical
                    justify={'center'}
                    align={'center'}
                    className={cn(
                        containerMinHeightMd,
                        containerMinHeightSm,
                        borderMd,
                        borderSm,
                        'bg-primary-bg-color h-full w-full p-5 border-2 border-solid',
                        isMobile ? (isVisiable ? 'visible' : 'invisible') : '',
                    )}
                    style={{ borderColor: currentThemeColor }}
                >
                    {children}
                </Flex>
            </Flex>
            <div
                onClick={() => {
                    if (isMobile) setIsVisable(false);
                }}
                ref={cardCloseIconRef}
                className={cn(
                    isVisiable
                        ? 'transition duration-300 ease-in-out visible'
                        : 'transition duration-300 ease-in-out invisible',
                    !isMobile ? 'hidden' : '',
                    'absolute top-4 right-4 cursor-pointer',
                )}
            >
                <CloseOutlined style={{ fontSize: 28 }} />
            </div>
        </div>
    );
};

export const VenueOverlayCard = ({ data, children, isTitleAppeare = true, style }: any) => {
    console.log('VenueOverlayCard==**************======', data);
    const containerMinHeightMd = style?.containerMinHeightMd || '';
    const containerMinHeightSm = style?.containerMinHeightSm || '';
    const borderMd = style?.borderMd || 'rounded';
    const borderSm = style?.borderSm || '';
    return (
        <div
            className={cn(
                borderMd,
                borderSm,
                containerMinHeightMd,
                containerMinHeightSm,
                'relative',
            )}
            style={{
                backgroundImage: `url(${withFullPath(data.venueHeroImage?.src)})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
            }}
        >
            {isTitleAppeare && (
                <div
                    className={cn(
                        borderMd,
                        borderSm,
                        'absolute bottom-0 h-auto w-full bg-gradient-to-t from-[#000] to-[rgba(0, 0, 0, 0.00)] px-5 py-6 z-0',
                    )}
                >
                    <Flex vertical gap={8}>
                        <Text
                            level={'subtitle1'}
                            text={data.facilities?.value}
                            className={'opacity-50'}
                        />
                        <Heading
                            className={'text-base-font-color'}
                            level={5}
                            text={data.venueName?.value}
                        />
                    </Flex>
                </div>
            )}
            <Flex
                className={`relative h-full w-full overflow-hidden bg-primary-bg-color/20 bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 `}
            >
                <Flex
                    vertical
                    justify={'center'}
                    align={'center'}
                    className={cn(
                        containerMinHeightMd,
                        containerMinHeightSm,
                        borderMd,
                        borderSm,
                        'bg-primary-bg-color h-full w-full p-5 border-2 border-solid border-[#EAE219]',
                    )}
                >
                    {children}
                </Flex>
            </Flex>
        </div>
    );
};
